import React from 'react';

import '../components.scss';

type Props = {
  customSVGStyle?: React.CSSProperties,
  className?: string,
  size?: number,
  animate?: boolean,
  text?: boolean
  fontSize?: number,
  gradientAmount?: number,
  textScale?: number
}

const Sbaraini = () => {

  return (
    <div className="sbaraini">
      <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 418.14 52.22"><defs></defs><title>FORMA-MKSBARAINI</title><path style={{ fill: '#958d9e' }} d="M411.51,52.22H43.19a11,11,0,0,1-8.31-3.81L1.57,9.71C-2.06,5.87,1,0,6.63,0H375a10.94,10.94,0,0,1,8.3,3.81l33.31,38.7C420.19,46.35,417.14,52.22,411.51,52.22Z"/></svg>
      <div className="text">SBARAINI</div>
    </div>
  )
}

const lightenDarken = (hex: string, amount: number) => {
  let num = parseInt(hex.replace('#', ''), 16),
    amt = Math.round(2.55 * amount),
    // eslint-disable-next-line
    R = (num >> 16) + amt,
    // eslint-disable-next-line
    B = (num >> 8 & 0x00FF) + amt,
    // eslint-disable-next-line
    G = (num & 0x0000FF) + amt;

  return '#' + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
}

const MkLogo: React.FC<Props> = ({ size = 100, customSVGStyle, className, animate = false, text = true, fontSize, gradientAmount = -40, textScale }) => {
  const gradID = (Math.random() + 1).toString(36).substring(7);
  const pathStyle = customSVGStyle;
  const pathKStyle =
    customSVGStyle?.fill
      ? { ...customSVGStyle, fill: lightenDarken(customSVGStyle.fill, gradientAmount) }
      : customSVGStyle;

  const gradientTop = customSVGStyle?.fill ? lightenDarken(customSVGStyle.fill, gradientAmount) : '#000000'

  const textStyle = textScale ? { transform: `scale(${textScale})`, transformOrigin: 0 } : {}

  return (
    <div className={"root-logo " + className} dir='ltr'>
      <svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="160 40 500 500" width={size} height={size} className={'logo-svg ' + (animate ? 'animate' : '')}>
        <defs>
          <linearGradient id={"grad-" + gradID} x1="0%" y1="100%" x2="0%" y2="0%">
            <stop offset="0%" style={{ stopColor: customSVGStyle?.fill || '#000000', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: gradientTop, stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <g>
          <path className="logo" style={{ ...pathStyle, fill: `url(#grad-${gradID})`}} d="M491.86,184.81v-40.43L414.07,279.1l-0.1,0.19c-1.98,3.43-4.66,6.68-7.75,9.57v5.38h12.84h0.02h0.55
		c2.37-0.08,4.62-0.67,6.65-1.65c0.51-0.25,1.03-0.54,1.55-0.86c2.58-1.61,4.7-3.9,6.08-6.61c0.1-0.2,0.22-0.38,0.35-0.55
		L491.86,184.81L491.86,184.81z M406.22,307.28v135.34v0.01h-0.01c0,4.57,1.88,8.73,4.9,11.75c3.02,3.02,7.18,4.9,11.75,4.9v-0.01
		h0.02h69v-52.36L406.22,307.28L406.22,307.28z M491.87,396.53l-82.1-95.5h9.29h0.02h11.53H445h46.87V396.53z"/>
          <polygon className="logo" points="491.87,396.53 491.87,301.03 445,301.03 430.62,301.03 419.08,301.03 419.06,301.03 409.77,301.03 	
		"/>
          <g>
            {/* PERNINHA DO KA  */}
            <path className="logo" style={pathKStyle} d="M409.77,301.03L545.8,459.27h99.99c1.77,0,3.14-0.25,4.1-0.72c0.73-0.35,1.19-0.85,1.39-1.44
			c0.27-0.81,0.2-1.93-0.22-3.31c-0.74-2.47-2.51-5.43-5.33-8.72L521.91,301.03H409.77z"/>
            <path className="logo" style={pathKStyle} d="M445,294.25h76.09L612.45,136h-66.04h-0.47v-0.01c-7.08,0-15.78,4.3-22.33,10.48
			c-1.25,1.18-2.43,2.45-3.53,3.81c-1.08,1.34-2.03,2.7-2.82,4.05l0,0l-0.02,0.04l-0.01,0.01l0,0l-77.17,133.67
			c-0.11,0.24-0.25,0.47-0.41,0.68c-1.11,2.04-2.51,3.9-4.14,5.51H445z"/>
            {/* FIM PERNINHA DO KA */}
          </g>
          <path className="logo" style={{ ...pathStyle, fill: `url(#grad-${gradID})`}}  d="M391.35,291.05l-36.24-62.79l-42.66-73.92l0,0l-0.01,0c-6.71-11.59-19.41-18.33-32.86-18.33h-62.36
		l80.61,139.63c0.13,0.17,0.24,0.36,0.33,0.55c0.78,1.31,1.68,2.6,2.68,3.83c1.07,1.32,2.26,2.59,3.54,3.8
		c6.54,6.16,15.21,10.43,22.29,10.43h52.75h0.02v0.01c2.21,0,4.64-0.45,7.13-1.26c0.39-0.13,0.81-0.27,1.25-0.44
		C389.01,292.13,390.19,291.62,391.35,291.05z"/>
          <path className="logo" style={{ ...pathStyle, fill: `url(#grad-${gradID})`}}  d="M214.32,144.5l77.85,134.84l0.01,0.02l0,0c0.98,1.69,2.13,3.33,3.42,4.92c1.27,1.56,2.67,3.06,4.15,4.46
		l0.21,0.2v8.69h0.01v161.64h-69.01h-0.01c-0.09,0-0.17,0-0.25,0.01c-4.47-0.07-8.53-1.93-11.5-4.89c-3.02-3.02-4.9-7.18-4.9-11.75
		h0.01v-0.02V297.64v-23.25V144.5z"/>
          <path className="logo" style={{ ...pathStyle, fill: `url(#grad-${gradID})`}}  d="M356.98,217.97l3.99,6.91l36.18,62.69c4.48-3.19,8.42-7.27,10.97-11.68l0.1-0.16L488.89,136h-66.48h-0.14
		l-0.12,0l-0.17-0.01l-0.32,0.01v0h-0.05c-1.28,0.06-2.66,0.27-4.09,0.6c-1.47,0.35-2.96,0.83-4.44,1.42
		c-7.69,3.1-15.29,9.26-19.36,16.31L356.98,217.97z"/>
        </g>
      </svg>
      {
        !text
          ? <span className={"logo-text " + (animate ? 'animate' : '')} style={textStyle}>
              <Sbaraini />
            </span>
          : null
      }
      {
        text
          ? <span className={"logo-text " + (animate ? 'animate' : '')} style={textStyle}>
              CAPITAL
              <Sbaraini />
            </span>
          : null
      }
      
    </div>
  );
}

export default MkLogo;