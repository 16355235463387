import React from "react";

type Props = {
  children?: any;
  transparent?: boolean,
  className?: string
}

const FormButtonGroup: React.FC<Props> = ({ children, transparent, className }) => (
  <div className={"form-button-group " + (transparent ? "transparent" : "") + (className ? " " + className : "")}>
    { children }
  </div>
);

export default FormButtonGroup;