import React from "react";
import FirstLogo from "./FirstLogo";
import LiquidezLogo from "./LiquidezLogo";
import PerformanceLogo from "./PerformanceLogo";

type Props = {
  id: number,
  color?: string
  size?: number
}

const FundLogo: React.FC<Props> = ({ id, color = 'white', size = 30 }) => {
  if ( id === 1 ) return <FirstLogo size={size} color={color} /> 
  if ( id === 2 ) return <LiquidezLogo size={size} color={color} />
  return <PerformanceLogo color={color} size={size} />
}

export default FundLogo;