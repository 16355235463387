import React from "react";

type ColorScheme = "colored" | "white";

type IconProps = {
  style?: React.CSSProperties;
  color?: ColorScheme;
}

type TextProps = {
  style?: React.CSSProperties;
  color?: ColorScheme;
}

type Props = {
  size?: number;
  color?: ColorScheme,
  className?: string;
  text?: boolean
}

const Text: React.FC<TextProps> = ({ style = {}, color }) => {
  const defaultStyle = { height: 60, marginLeft: 20, ...style };
  const fill1 = color !== 'white' ? '#133751' : '#ffffff';
  const fill2 = color !== 'white' ? '#D9A556' : '#ffffff';

  return (
    <svg  viewBox="0 0 360.3 113.7"  xmlns="http://www.w3.org/2000/svg" style={defaultStyle}  version="1.1">
      <g>
        <title>Layer 1</title>
        <g id="Camada_2" />
        <g id="Camada_1">
          <g id="svg_1">
            <g id="svg_8">
              <path id="svg_9" fill={fill1} d="m93.5,95.1c-1,1 -2,1.7 -3.1,2.2c-1.1,0.5 -2.3,0.8 -3.5,0.8c-1.3,0 -2.5,-0.2 -3.6,-0.7c-1.1,-0.5 -2,-1.1 -2.7,-2c-0.8,-0.8 -1.3,-1.7 -1.7,-2.8c-0.4,-1 -0.6,-2.1 -0.6,-3.2c0,-1.3 0.2,-2.4 0.7,-3.5s1.1,-2 1.9,-2.8c0.8,-0.8 1.7,-1.4 2.8,-1.8c1,-0.4 2.1,-0.6 3.2,-0.6c1.1,0 2.3,0.2 3.4,0.7c1.1,0.5 2.1,1.2 3,2l-2.4,2.4c-0.6,-0.6 -1.2,-1 -1.9,-1.3c-0.7,-0.3 -1.4,-0.5 -2.1,-0.5c-0.7,0 -1.4,0.1 -2,0.4c-0.6,0.3 -1.2,0.7 -1.7,1.1c-0.5,0.5 -0.9,1 -1.1,1.7c-0.3,0.6 -0.4,1.3 -0.4,2.1c0,0.7 0.1,1.4 0.4,2c0.3,0.6 0.6,1.2 1.1,1.7c0.5,0.5 1,0.9 1.7,1.1c0.6,0.3 1.3,0.4 2,0.4c0.8,0 1.6,-0.2 2.2,-0.5c0.7,-0.3 1.3,-0.8 2,-1.5l2.4,2.6z" className="st3" />
              <path id="svg_10" fill={fill1} d="m147.2,97.9l-3.4,0l0,-16.9l6.9,0c0.7,0 1.4,0.1 2,0.4c0.6,0.3 1.2,0.6 1.7,1.1c0.5,0.5 0.9,1 1.1,1.7c0.3,0.6 0.4,1.3 0.4,2c0,0.7 -0.1,1.4 -0.4,2.1c-0.3,0.6 -0.6,1.2 -1.1,1.7c-0.5,0.5 -1,0.8 -1.7,1.1c-0.6,0.3 -1.3,0.4 -2,0.4l-3.5,0l0,6.4l0,0zm0,-9.8l2.9,0c0.9,0 1.5,-0.2 1.9,-0.6c0.4,-0.4 0.6,-0.8 0.6,-1.3c0,-0.5 -0.2,-1 -0.6,-1.3c-0.4,-0.4 -1,-0.5 -1.9,-0.5l-2.9,0l0,3.7z" className="st3" />
              <path id="svg_11" fill={fill1} d="m180.1,97.9l0,-16.9l3.3,0l0,17l-3.3,0l0,-0.1z" className="st3" />
              <path id="svg_12" fill={fill1} d="m220.2,84.3l-4.7,0l0,13.6l-3.4,0l0,-13.6l-4.7,0l0,-3.3l12.7,0l0,3.3l0.1,0z" className="st3" />
              <path id="svg_13" fill={fill1} d="m282.9,97.9l-10.2,0l0,-16.9l3.4,0l0,13.6l6.8,0l0,3.3z" className="st3" />
              <g id="svg_14">
                <g id="svg_15">
                  <path id="svg_16" fill={fill1} d="m114.6,94.8l0,0l1.5,-3.4l0,0l1.7,-3.9l0,0l0,0l4.6,10.4l3.7,0l-7.6,-17.1l-1.4,0l-7.5,17.1l3.7,0c0.2,-0.5 0.4,-1 0.7,-1.5c0.1,-0.6 0.4,-1.1 0.6,-1.6z" className="st3" />
                </g>
              </g>
              <g id="svg_17">
                <g id="svg_18">
                  <path id="svg_19" fill={fill1} d="m242.5,94.8l0,0l1.5,-3.4l0,0l1.7,-3.9l0,0l0,0l4.6,10.4l3.7,0l-7.6,-17.1l-1.4,0l-7.5,17.1l3.7,0c0.2,-0.5 0.4,-1 0.7,-1.5c0.2,-0.6 0.4,-1.1 0.6,-1.6z" className="st3" />
                </g>
              </g>
            </g>
            <g id="svg_20">
              <path id="svg_21" fill={fill2} d="m17.8,63.3c-1.9,0 -3.8,-0.3 -5.7,-0.8c-1.8,-0.6 -3.6,-1.3 -5.2,-2.4l0.1,0c-1.2,-0.7 -2.2,-1.5 -3.1,-2.4c-0.9,-0.8 -1.5,-1.5 -1.8,-1.8l6.6,-6.1c0.3,0.4 0.8,0.8 1.4,1.4c0.7,0.5 1.4,1.1 2.3,1.5c0.8,0.5 1.7,0.9 2.7,1.2c0.9,0.3 1.8,0.5 2.7,0.5c0.7,0 1.4,-0.1 2.2,-0.3c0.7,-0.2 1.4,-0.4 2,-0.8c0.6,-0.4 1.1,-0.8 1.5,-1.4c0.4,-0.6 0.6,-1.3 0.6,-2.2c0,-0.3 -0.1,-0.7 -0.3,-1.1c-0.2,-0.4 -0.6,-0.8 -1.2,-1.3s-1.4,-1 -2.4,-1.5s-2.3,-1.1 -3.8,-1.7c-1.8,-0.7 -3.5,-1.4 -5,-2.2s-2.9,-1.7 -4,-2.8c-1.1,-1.1 -2,-2.3 -2.6,-3.6c-0.6,-1.4 -0.9,-2.9 -0.9,-4.7c0,-1.8 0.3,-3.5 1,-5.1c0.7,-1.6 1.7,-3 2.9,-4.1c1.3,-1.2 2.8,-2.1 4.5,-2.7s3.7,-1 5.8,-1c1.2,0 2.4,0.2 3.7,0.4s2.6,0.7 3.8,1.2c1.2,0.5 2.4,1 3.5,1.6c1.1,0.6 2,1.2 2.7,1.9l-5.5,6.9c-0.8,-0.6 -1.6,-1.1 -2.5,-1.5c-0.7,-0.4 -1.6,-0.7 -2.5,-1.1c-0.9,-0.3 -1.9,-0.5 -2.9,-0.5c-1.8,0 -3.2,0.3 -4.2,1.1c-1,0.7 -1.5,1.7 -1.5,3c0,0.6 0.2,1.1 0.6,1.6c0.4,0.5 0.8,0.9 1.5,1.3c0.6,0.4 1.3,0.8 2.1,1.1c0.8,0.3 1.7,0.7 2.6,0.9c2.6,1 4.7,2 6.5,3c1.7,1 3.2,2.1 4.2,3.2c1.1,1.1 1.8,2.3 2.3,3.6s0.7,2.7 0.7,4.2c0,1.9 -0.4,3.6 -1.2,5.3c-0.8,1.7 -1.8,3.1 -3.2,4.3s-2.9,2.2 -4.8,2.9c-1.9,0.7 -4,1 -6.2,1z" className="st1" />
              <path id="svg_22" fill={fill2} d="m194,62.9l-10.9,0l-12.1,-17l-5.3,0l0,17l-8.8,0l0,-44.7l18,0c1.9,0 3.6,0.4 5.3,1.1c1.7,0.7 3.1,1.7 4.4,3c1.3,1.3 2.3,2.7 3,4.4c0.7,1.7 1.1,3.5 1.1,5.3c0,2.8 -0.7,5.3 -2.2,7.6c-1.4,2.2 -3.3,3.9 -5.7,5l13.2,18.3zm-20.7,-25.9c2.3,0 3.9,-0.5 5,-1.5c1.1,-1 1.6,-2.2 1.6,-3.5c0,-1.4 -0.5,-2.5 -1.6,-3.5c-1.1,-0.9 -2.7,-1.4 -5,-1.4l-7.6,0l0,10l7.6,0l0,-0.1z" className="st1" />
              <path id="svg_23" fill={fill2} d="m263.9,62.9l0,-44.7l8.8,0l0,44.7l-8.8,0l0,0z" className="st1" />
              <path id="svg_24" fill={fill2} d="m291.2,17.8l3.9,0l24.1,26.8l0,-26.4l8.8,0l0,45.1l-4,0l-24,-26.7l0,26.3l-8.8,0l0,-45.1l0,0z" className="st1" />
              <path id="svg_25" fill={fill2} d="m350.6,62.9l0,-44.7l8.8,0l0,44.7l-8.8,0l0,0z" className="st1" />
              <g id="svg_26">
                <g id="svg_27">
                  <path id="svg_28" fill={fill2} d="m83.9,46.6c-0.3,-0.9 -0.6,-1.8 -1.1,-2.7c-0.5,-0.9 -1.2,-1.7 -2,-2.4s-1.8,-1.4 -2.8,-1.9c1.8,-1.3 3.1,-2.8 3.7,-4.4c0.7,-1.7 1,-3.2 1,-4.7c0,-1.6 -0.3,-3.1 -1.1,-4.6c-0.7,-1.5 -1.7,-2.8 -2.9,-3.9c-1.2,-1.1 -2.7,-2 -4.3,-2.7c-1.7,-0.7 -3.4,-1 -5.3,-1l-17.9,0l0,44.7l18.1,0c2.9,0 5.3,-0.4 7.2,-1.2c1.9,-0.8 3.5,-1.8 4.7,-3.1c1.2,-1.3 2,-2.7 2.5,-4.3c0.5,-1.6 0.7,-3.2 0.7,-4.9c-0.1,-1 -0.2,-1.9 -0.5,-2.9zm-10.6,6c-1.1,0.9 -2.8,1.4 -5.2,1.4l-8.1,0l0,-9.5l0,0l0,-8.9l0,0l0,-8.6l8,0c1.6,0 2.8,0.4 3.8,1.3c0.9,0.9 1.4,1.9 1.4,3.2c0,1.2 -0.5,2.2 -1.4,3c-0.9,0.7 -2.2,1.1 -3.8,1.1l-4,0l0,8.9l4.1,0c1.3,0 2.4,0.1 3.3,0.4c0.9,0.3 1.6,0.6 2.1,1.1c0.5,0.4 0.9,1 1.1,1.6c0.2,0.6 0.3,1.2 0.3,1.8c0,1.2 -0.5,2.3 -1.6,3.2z" className="st1" />
                </g>
              </g>
              <g id="svg_29">
                <g id="svg_30">
                  <path id="svg_31" fill={fill2} d="m119.2,17.8l-3.8,0l-19.8,45.1l9.7,0c0.5,-1.2 1.1,-2.6 1.8,-4.1s1.3,-2.8 1.8,-4.1l0,0l3.9,-8.9l-0.1,0l4.6,-10.3l12.2,27.4l9.8,0l-20.1,-45.1z" className="st1" />
                </g>
              </g>
              <g id="svg_32">
                <g id="svg_33">
                  <path id="svg_34" fill={fill2} d="m226.3,17.8l-3.8,0l-19.8,45.1l9.7,0c0.5,-1.2 1.1,-2.6 1.8,-4.1s1.3,-2.8 1.8,-4.1l0,0l3.9,-8.9l-0.1,0l4.6,-10.3l12.2,27.4l9.8,0l-20.1,-45.1z" className="st1" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

const Icon: React.FC<IconProps> = ({ style = {}, color = 'white' }) => {
  return (
    <svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 118.7 113.7" className={"icon " + (color === 'white' ? 'white' : '')} style={{ ...style }}>
      <g>
        <g>
          <path className="st2" d="M52.1,79.6H19.9l-0.3-0.3L4,63.7C0.3,60-1,54.4,0.8,49.3c1.7-4.9,4-9.7,6.8-14.2l38.8,38.8L52.1,79.6z" />
          <g>
            <path className="st2" d="M109.9,79.6c-0.6,3.9-1.7,7.7-3.4,11.4c-1.7,3.7-4.1,7.1-7,10.2c-0.1,0.1-0.1,0.1-0.1,0.2l-1.5,1.5
                        c-0.1,0.1-0.1,0.1-0.2,0.1c-1.1,1-2.2,2-3.4,2.9c-3.2,2.4-6.8,4.3-10.5,5.6c-4.3,1.5-8.8,2.3-13.4,2.3H6.6l1-1L24.3,96l5-5h41.2
                        c2.5,0,5-0.6,7.4-1.7c1.7-0.8,3.3-1.9,4.7-3.3c1.9-1.8,3.2-4,4-6.4c2.1-6,0.8-12.9-4-17.8c-1.4-1.4-3-2.5-4.7-3.3
                        c-6.3-3-14.2-1.9-19.4,3.3l-4,4L38.6,50.1c1.1-1.5,2.4-3,3.7-4.3c11.2-11.2,27.5-14.3,41.5-9.4c3.7,1.3,7.2,3.2,10.5,5.6
                        c1.5,1.1,2.9,2.4,4.3,3.7C107.9,55,111.7,67.6,109.9,79.6z"/>

            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="118.6613" y1="89.2719" x2="7.5813" y2="89.2719" gradientTransform="matrix(1 0 0 -1 0 115.1969)">
              <stop offset="0" style={{ stopColor: '#D6A254' }} />
              <stop offset="0.8512" style={{ stopColor: '#A3763E' }} />
            </linearGradient>
            <path className="st3" d="M118.7,17.8l-16.2,16.2c-3.1-2.5-6.4-4.5-9.9-6.2c-19-9.1-42.5-5.8-58.2,10c-1.4,1.3-2.6,2.8-3.8,4.2
                        c-2.5,3.1-4.5,6.4-6.2,9.9L7.6,35.1c2-3.2,4.2-6.4,6.8-9.3c0.8-0.9,1.6-1.8,2.4-2.6l3-3C34,6.7,52.3,0,70.5,0
                        c13.5,0,27,3.7,38.8,11C112.6,13,115.7,15.2,118.7,17.8z"/>
            <path className="st0" d="M74.6,77.9c-1.1,1.1-2.6,1.7-4,1.7c-1.5,0-2.9-0.6-4-1.7c-2.2-2.2-2.2-5.8,0-8c2.2-2.2,5.8-2.2,8,0
                        C76.8,72.1,76.8,75.7,74.6,77.9z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

const SbarainiLogo: React.FC<Props> = ({ size, color = 'colored', className = '', text = true }) => {
  const textSize = size ? size * 1.2 : 30;
  return (
    <div className={"sbaraini-logo d-flex align-items-center " + className}>
      <Icon style={{ width: size }} color={color} />
      {
        text
          ? <Text style={{ height: textSize }} color={color} />
          : null
      }
    </div>
  )
}

export default SbarainiLogo;