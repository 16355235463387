export default class FundUtils {
    static getBackgroundColor(fund: number) {
        if ( fund === 1 ) {
            return 'bg-first';
        }

        if ( fund === 2 ) {
            return 'bg-liquidez';
        }

        return 'bg-performance';
    }
}