import useTranslation from "hooks/useTranslation";
import React from "react";
import { IoGlobeOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { NavigationTypes } from "reducers/navigationReducer";
import { ApplicationState } from "store";
import AppStorage from "utils/AppStorage";
import Dropdown, { DropdownItem } from "./ui/Dropdown";

const SwitchLang = ({ className = '', short = false }) => {
  const locale = useSelector((state: ApplicationState) => state.navigation.locale);
  const dispatch = useDispatch();
  const i = useTranslation();
  const showArabicLanguage = window.location.host === 'ar.mkcapital.com.br';

  const onLangChange = (v: string) => {
    AppStorage.set('locale', v);
    dispatch({ type: NavigationTypes.UPDATE_LOCALE, payload: v });
  }

  return (
    <>
      <Dropdown className={className} title={short ? locale.toUpperCase() : i._('langs.' + locale)} icon={<IoGlobeOutline size={20} />}>
        <DropdownItem title={i._('langs.pt')} value="pt" onClick={onLangChange} />
        <DropdownItem title={i._('langs.en')} value="en" onClick={onLangChange} />
        {
          showArabicLanguage
            ? <DropdownItem title={i._('langs.ar')} value="ar" onClick={onLangChange} />
            : null
        }
      </Dropdown>
    </>
  )
}

export default SwitchLang;